 <template>
  <div>
    <CModal color="primary" :show="showModal">
      <template v-slot:header>
        <h5 class="modal-title">Brand Details</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal(showModal)"
        >
          ×
        </button>
      </template>
      <div class="text-center" v-if="brand">
         <img height="150px" :src="brand ? brand.image : null" />
      </div>
     
      <p class="mt-5">
        Name:
        {{ brand ? brand.name : null }}.
      </p>
      <p >
        Product Group:
        {{ brand ? brand.product_group.name : null }}.
      </p>
      <p >
        Description:
        {{ brand ? brand.description : null }}.
      </p>
      <p >
        Created by:
        {{ brand ? brand.created_by.username : null }}.
      </p>
      <p >
        Updated by:
        {{ brand ? brand.updated_by.username : null }}.
      </p>

      <template v-slot:footer>
        <CButton color="primary" @click="closeModal(showModal)">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "BrandView",
  computed: {
    ...mapState({
      showModal: (state) => state.brands.showModal,
      brand: (state) => state.brands.showBrand,
    }),
  },
  methods: {
    closeModal(bool) {
      this.$store.commit("brands/SHOW_MODAL", {
        bool: bool,
        brand: null,
      });
    },
  },
};
</script>